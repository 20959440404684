.article > h1 {
  font-size: 2em;
  font-weight: bold;
  margin-top: 0.3em;
  margin-bottom: 0.5em;
}

.article > h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.article > p {
  margin-bottom: 0.4em;
}